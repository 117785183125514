body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Cafe24 Ssurround";
  src: url("./Assets/fonts/Cafe24Ssurround-v2.0.woff2") format("woff2"),
       url("./Assets/fonts/Cafe24Ssurround-v2.0.woff") format("woff"),
       url("./Assets/fonts/Cafe24Ssurround-v2.0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}


.text-gradient {
  background: linear-gradient(45deg, #015a68 0%, #319694 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

#image-wrapper {
  background-image: url('./Assets/main2.webp');
  background-size: cover;
  background-position: center center;
}

@media (min-width: 768px) {
  #image-wrapper {
    background-image: url('./Assets/main.webp');
  }
}

@media (max-width: 990px) and (orientation: landscape) {
  #image-wrapper {
    background-image: url('./Assets/main.webp');
  }
}

